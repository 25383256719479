import { useEffect, useState } from "react";
import useApp from "../../hooks/useApp";
import { useAccount, useNetwork } from "wagmi";
// import useAnalysis from "../../hooks/useAnalysis";
import useErc20 from "../../hooks/useERC20";

import textStaking from "../../assets/image/text-staking.png";

export default function Pool() {
  const { chain } = useNetwork();
  const { address } = useAccount();

  const { getReward, getUserStakes, harvest, withdraw } = useApp();
  const [availableRewards, setAvailableRewards] = useState(0);
  const [canUnlocked, setCanUnlocked] = useState(false);
  const [info, setInfo] = useState({
    totalAmount: 0,
    lastClaimTime: 0,
    stakingTime: "",
  });

  const harvestButtonClicked = async () => {
    await harvest();
    getData();
  };

  const withdrawButtonClicked = async () => {
    await withdraw();
    getData();
  };

  const getData = async () => {
    if (chain?.unsupported) return;
    const { totalAmount, lastClaimTime, stakingTime } = await getUserStakes();
    var ms = new Date(stakingTime * 1000).getTime() + 86400000 * 3;
    setCanUnlocked(ms < new Date().getTime());
    var unlock = new Date(ms);
    unlock =
      unlock.toLocaleString() === "3/1/1970, 7:00:00 AM"
        ? ""
        : unlock.toLocaleString();
    setInfo({ totalAmount, lastClaimTime, stakingTime: unlock });
    const availableRewards = await getReward();
    setAvailableRewards(availableRewards);
  };

  useEffect(() => {
    getData();
  }, [chain, address]);

  return (
    <div className="text-center py-16">
      <div className="bg-white/50 px-5 py-2 rounded-sm border-black border-[1px] text-black w-fit mx-auto">
        <span className="">{process.env.REACT_APP_CONTRACT}</span>
      </div>

      <div className="max-w-lg mx-auto my-8">
        <img src={textStaking} alt="" />
      </div>

      <div className="box-shadow-custom-1 relative z-50 border-[1px] border-black bg-[#4a50f1] rounded-sm w-[350px] lg:w-[800px] mx-auto p-10 lg:px-8 px-5 pt-5">
        <h2 className="font-bold text-2xl">OVERVIEW</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
          <div className="border-white border-[1px] bg-black/80 p-5 rounded-md flex flex-col">
            <h3 className="font-bold h-[48px]">AVAILABLE REWARDS:</h3>
            <input
              disabled
              value={availableRewards}
              className="block px-2 bg-black border-white border-[1px] rounded-md text-center font-bold py-1.5 my-8"
            />
            <button
              className="btn-primary px-5 py-2 rounded-md font-bold w-fit mx-auto"
              onClick={harvestButtonClicked}
            >
              COLLECT REWARD
            </button>
          </div>
          <div className="border-white border-[1px] bg-black/80 p-5 rounded-md flex flex-col">
            <h3 className="font-bold uppercase h-[48px]">
              unstake available: <br /> {info.stakingTime}
            </h3>
            <input
              disabled
              value={canUnlocked ? Number(info.totalAmount.toFixed(2)) : 0}
              className="block px-2 bg-black border-white border-[1px] rounded-md text-center font-bold py-1.5 my-8"
            />
            <button
              disabled={!canUnlocked}
              className="btn-primary px-5 py-2 rounded-md font-bold w-fit mx-auto"
            >
              UNSTAKE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
