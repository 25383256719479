import { useAccount } from "wagmi";
import abi from "../utils/abi";
import { ethers } from "ethers";
import useErc20 from "./useERC20";
const useApp = () => {
  const { address } = useAccount();
  const { approve } = useErc20();
  const getTotalStaked = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL
    );

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const value = await contract.totalStaked();
    return +ethers.utils.formatEther(value);
  };

  const getReward = async () => {
    if (!address) return 0;
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL
    );

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const value = await contract.getReward(address);
    return +ethers.utils.formatEther(value);
  };

  const getUserStakes = async () => {
    if (!address) return {
      totalAmount: 0,
      lastClaimTime: 0,
      stakingTime: 0,
    };

    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL
    );

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const value = await contract.userStakes(address);

    return {
      totalAmount: +ethers.utils.formatEther(value.totalAmount),
      lastClaimTime: value.lastClaimTime.toNumber(),
      stakingTime: value.stakingTime.toNumber(),
    };

  };

  const stake = async (amount) => {
    if (!address) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );

    await approve(amount);
    const tx = await contract.stake(ethers.utils.parseEther(amount.toString()));
    await tx.wait();
  };

  const harvest = async () => {
    if (!address) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );

    const tx = await contract.harvest();
    await tx.wait();
  };

  const withdraw = async () => {
    if (!address) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );

    const tx = await contract.withdraw();
    await tx.wait();
  };

  return {
    getTotalStaked,
    getReward,
    getUserStakes,
    stake,
    harvest,
    withdraw
  };
};

export default useApp;
